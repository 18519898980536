var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-md-start justify-space-around align-center flex-wrap"},[_c('v-btn',{staticClass:"pl-2 light-border",attrs:{"outlined":"","rounded":"","color":"primary","data-cy":"v-btn__header--prevWeek","tabindex":"0","aria-label":`${_vm.$t('timesheetUI.Btns.BtnPrevWeek')}`,"disabled":_vm.isWeekStartDateBeforeMinDateForTimesheetNavigation ||
      !_vm.isEmpty(_vm.preparingForAutoSave)},on:{"focus":function($event){_vm.currentDayAnnouncement = ''},"click":function($event){return _vm.navigateTimesheetByWeek('lastweek')}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-chevron-left")]),_c('span',{class:`${
        _vm.isWeekStartDateBeforeMinDateForTimesheetNavigation
          ? ''
          : 'font-color--light'
      }font-size--14 font-weight--600`},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.Btns.BtnPrevWeek'))+" ")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{ref:"menu",attrs:{"disabled":!_vm.isEmpty(_vm.preparingForAutoSave),"transition":"slide-x-transition","offset-y":"","close-on-content-click":false},on:{"input":_vm.focusOnDatePicker},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({ref:"calendarButton",staticClass:"mx-3 btn-datepicker light-border rounded px-1",attrs:{"outlined":"","rounded":"","color":"action","tabindex":"0","aria-label":`Calendar button: current Selected Week ${_vm.selectedWeekRangeText}`,"min-width":"190"}},on),[_c('v-icon',{staticClass:"font-color--light mr-2",attrs:{"color":"secondary"}},[_vm._v("mdi-calendar")]),_c('span',{staticClass:"secondary--text font-size--14 font-weight--400"},[_vm._v(_vm._s(_vm.selectedWeekRangeText))]),_c('v-icon',{staticClass:"font-color--light ml-2",attrs:{"color":"secondary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2704806232),model:{value:(_vm.currentTimesheetDatePicker),callback:function ($$v) {_vm.currentTimesheetDatePicker=$$v},expression:"currentTimesheetDatePicker"}},[_c('div',[_c('FocusLock',{attrs:{"returnFocus":""}},[_c('v-date-picker',{attrs:{"color":"accent","range":_vm.range,"value":_vm.range
              ? [_vm.selectedDate.startOfWeek, _vm.selectedDate.endOfWeek]
              : this.selectedDate.startOfWeek,"scrollable":"","first-day-of-week":"1","locale-first-day-of-year":7,"no-title":"","max":_vm.maxDate,"weekday-format":_vm.getDay,"min":_vm.minDateForTimesheetNavigation},on:{"click:date":function($event){return _vm.dateSelectedHandler($event)},"update:picker-date":_vm.setAriaLabels}},[_c('div',{staticClass:"px-1 col py-0"},[_c('v-btn',{staticClass:"font-size--14 light-border col",attrs:{"outlined":"","rounded":""},on:{"click":function($event){return _vm.navigateTimesheetByWeek($event)}}},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.HeaderDatePicker.BtnCurrentWeek'))+" ")])],1)])],1)],1)]):_vm._e(),_c('v-btn',{staticClass:"pr-2 light-border",attrs:{"outlined":"","rounded":"","color":"primary","data-cy":"v-btn__header--nextWeek","disabled":!_vm.isEmpty(_vm.preparingForAutoSave),"tabindex":"0","aria-label":`${_vm.$t('timesheetUI.Btns.BtnNextWeek')}`},on:{"click":function($event){return _vm.navigateTimesheetByWeek('nextweek')},"focus":_vm.announceCurrentDay,"blur":function($event){_vm.currentDayAnnouncement = ''}}},[_c('span',{staticClass:"font-size--14 font-weight--600"},[_vm._v(" "+_vm._s(_vm.$t('timesheetUI.Btns.BtnNextWeek'))+" ")]),_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-chevron-right")])],1),_c('span',{staticClass:"sr-only",attrs:{"aria-label":`${_vm.currentDayAnnouncement}`,"aria-live":"assertive"}}),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex",staticStyle:{"max-width":"255px","cursor":"pointer"},on:{"click":function($event){_vm.currentTimesheetDatePicker = true}}},[_c('v-icon',{staticClass:"ml-3 mr-2"},[_vm._v("mdi-calendar-month")]),_c('v-text-field',{staticStyle:{"width":"250px"},attrs:{"value":_vm.selectedWeekRangeText,"readonly":"","outlined":"","dense":"","hide-details":""}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }