import { render, staticRenderFns } from "./OnBehalfOfModal.vue?vue&type=template&id=47151dc0&scoped=true"
import script from "./OnBehalfOfModal.vue?vue&type=script&lang=js"
export * from "./OnBehalfOfModal.vue?vue&type=script&lang=js"
import style0 from "./OnBehalfOfModal.vue?vue&type=style&index=0&id=47151dc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47151dc0",
  null
  
)

export default component.exports