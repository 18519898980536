var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap mt-2 justify-space-between align-center"},[_c('span',{staticClass:"primary--text font-size--12 w-60px"},[_vm._v(_vm._s(_vm.$t('timesheetUI.EntryFormLabels.TimesheetEntryPsTask')))]),_c('VSelect',{ref:"joblist_ppm_select",staticClass:"font-size--14",attrs:{"value":_vm.selectedTasks.PPMTask || '',"items":(_vm.PPMTasksByJobId[_vm.job.job_ID] && _vm.PPMTasksByJobId[_vm.job.job_ID].data) || [],"loading":_vm.PPMTasksByJobId[_vm.job.job_ID] && _vm.PPMTasksByJobId[_vm.job.job_ID].isDataLoading,"placeholder":_vm.$t('timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder'),"item-text":"label","item-value":"value","outlined":"","attach":"","dense":"","menu-props":{
      bottom: true,
      offsetY: true,
      nudgeBottom: 0,
    },"hide-details":"","append-icon":"mdi-chevron-down","error":_vm.error},on:{"input":function($event){return _vm.setSelectedTask(_vm.job.job_ID, 'PPMTask', $event)},"click":function($event){return _vm.loadPPMTasks(_vm.job, _vm.PPMTasksByJobId[_vm.job.job_ID])},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.loadPPMTasks(_vm.job, _vm.PPMTasksByJobId[_vm.job.job_ID])},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.loadPPMTasks(_vm.job, _vm.PPMTasksByJobId[_vm.job.job_ID])}]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(
          !_vm.PPMTasksByJobId[_vm.job.job_ID] ||
          (_vm.PPMTasksByJobId[_vm.job.job_ID] &&
            _vm.PPMTasksByJobId[_vm.job.job_ID].isDataLoading)
        )?_c('div',[_c('VListItem',[_c('VListItem',[_c('VListItemContent',[_c('VListItemTitle',[_vm._v("Loading...")])],1)],1)],1)],1):_c('div',[_c('VListItem',[_c('VListItemContent',[_c('VListItemTitle',[_vm._v(_vm._s(_vm.$t('General.Nodataavailable')))])],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }