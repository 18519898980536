var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[_c('div',{attrs:{"aria-label":_vm.IsPercentageByDay[_vm.index].some((entry) => entry === true)
        ? `${_vm.hoursTotalPerDay[_vm.index].percent} Percent`
        : `${_vm.hoursTotalPerDay[_vm.index].hours} Hours`}},[_c('span',{class:{
        'asterisk-indicator': _vm.isThresholdError(
          _vm.IsPercentageByDay[_vm.index].some((entry) => entry === true),
          _vm.hoursTotalPerDay[_vm.index],
          _vm.index
        ),
      }},[(_vm.mixedTimeEntry)?_c('span',[_vm._v(_vm._s(_vm.IsPercentageByDay[_vm.index].some((entry) => entry === true) ? _vm.hoursTotalPerDay[_vm.index].percent : _vm.hoursTotalPerDay[_vm.index].hours))]):_vm._e(),(!_vm.mixedTimeEntry)?_c('span',[_vm._v(" "+_vm._s(_vm.agencyData.isPercentageAgency ? _vm.hoursTotalPerDay[_vm.index].percent : _vm.hoursTotalPerDay[_vm.index].hours)+" ")]):_vm._e(),(_vm.mixedTimeEntry)?_c('span',[_vm._v(_vm._s(_vm.IsPercentageByDay[_vm.index].some((entry) => entry === true) ? '%' : 'h'))]):_vm._e()])]),(
      _vm.day.isRequiredByUserContract &&
      _vm.isThresholdError(
        _vm.IsPercentageByDay[_vm.index].some((entry) => entry === true),
        _vm.hoursTotalPerDay[_vm.index],
        _vm.index
      )
    )?_c('timesheet-tooltip-v2',{attrs:{"top":""},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('v-btn',{attrs:{"role":"button","icon":"","x-small":"","color":"error","aria-describedby":`dailyHoursMessage${_vm.index}`,"aria-label":"warning message"}},[_c('v-icon',[_vm._v("mdi-information-outline")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"role":"tooltip","aria-hidden":"true","id":`dailyHoursMessage${_vm.index}`},domProps:{"innerHTML":_vm._s(_vm.getTooltipMessage())}})],1)]},proxy:true},{key:"body",fn:function(){return [_c('span',{attrs:{"id":`tooltipContentForDay${_vm.index}`}},[(_vm.agencyData.isPercentageAgency && _vm.mixedTimeEntry)?_c('span',[(_vm.IsPercentageByDay[_vm.index].some((entry) => entry === true))?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('timesheetUI.Tooltips.Threshold.alertPercent', {
                hours: _vm.getRemainingHours(
                  true,
                  _vm.hoursTotalPerDay[_vm.index].percent
                ),
              })
            )}}):_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('timesheetUI.Tooltips.Threshold.alertHours', {
                hours: _vm.getRemainingHours(
                  false,
                  this.timesheetHoursPerDayComputed.hoursPerDay[_vm.index] -
                    _vm.hoursTotalPerDay[_vm.index].hours
                ),
              })
            )}})]):_c('span',[(_vm.agencyData.isPercentageAgency)?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('timesheetUI.Tooltips.Threshold.alertPercent', {
                hours: _vm.getRemainingHours(
                  true,
                  _vm.hoursTotalPerDay[_vm.index].percent
                ),
              })
            )}}):(_vm.firstAvailableContract.isTaskAgency)?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('timesheetUI.Tooltips.Threshold.alertOvertime', {
                hours: _vm.getRemainingHours(
                  false,
                  _vm.agencyData.timesheetHoursPerDay +
                    1 -
                    (_vm.hoursTotalPerDay[_vm.index].hours -
                      _vm.hoursTotalPerDay[_vm.index].overtime)
                ),
              })
            )}}):_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('timesheetUI.Tooltips.Threshold.alertHours', {
                hours: _vm.getRemainingHours(
                  false,
                  _vm.timesheetHoursPerDayComputed.hoursPerDay[_vm.index] -
                    _vm.hoursTotalPerDay[_vm.index].hours
                ),
              })
            )}})]),_c('span',[_vm._v(" "+_vm._s(_vm.firstAvailableContract.dwIndicator === '1' ? _vm.$t('timesheetUI.Tooltips.Threshold.alertDW1') : (_vm.part2 = _vm.$t('timesheetUI.Tooltips.Threshold.alertDW2')))+" ")])])]},proxy:true}],null,false,1860531668)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }