var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSelect',{staticClass:"font-size--14",attrs:{"value":_vm.jobsSortOrder,"items":_vm.dropdownJobItem,"item-text":"text","placeholder":"Sort jobs","dense":"","outlined":"","menu-props":{
    positionY: 60,
    absolute: true,
    minWidth: '100%',
  },"attach":"#sortDropdownDontainer","hide-details":"","append-icon":"mdi-chevron-down","aria-label":`Sort Jobs Options. Selected: ${
    _vm.dropdownJobItem.find((a) => a.value === _vm.jobsSortOrder)?.text
  }`},on:{"change":_vm.handleSortOptionChange,"keydown":_vm.arrowKeyHandler},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('VIcon',{attrs:{"color":"primary"}},[_vm._v("mdi-sort-variant")])]},proxy:true},{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"secondary--text"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.sortName))]),_vm._v("   "),_c('span',[_vm._v(_vm._s(item.sortDescription))])])]}},{key:"item",fn:function({ attrs, on, item }){return [_c('VListItem',_vm._g(_vm._b({staticClass:"rounded-lg"},'VListItem',attrs,false),on),[_c('VListItemContent',[_c('VListItemTitle',{staticClass:"primary--text"},[_c('span',[_vm._v(_vm._s(item.sortName))]),_vm._v("   "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(item.sortDescription))])])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }