var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showTimesheetDialog)?_c('div',[_c('v-dialog',{attrs:{"max-width":_vm.dialogWidth,"transition":false,"overlay-opacity":"0.09"},model:{value:(_vm.showTimesheetDialog),callback:function ($$v) {_vm.showTimesheetDialog=$$v},expression:"showTimesheetDialog"}},[_c('FocusLock',{attrs:{"returnFocus":""}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-form',{attrs:{"lazy-validation":"","disabled":(_vm.showForm &&
                _vm.isTimesheetApproved &&
                !_vm.isTimesheetBeingCorrected) ||
              (_vm.timesheetDetail && _vm.timesheetDetail.selfCorrectedHours < 0)},on:{"submit":function($event){$event.preventDefault();return _vm.saveValidate.apply(null, arguments)}}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-start pa-4"},[_c('div',{staticClass:"timesheet-entry__header"},[_c('h1',{staticClass:"h1 font-weight--900 mb-1"},[_vm._v(" "+_vm._s(_vm.timesheetExistsDate)+" ")]),_c('div',{staticClass:"h5 d-flex flex-wrap"},[_vm._v(" "+_vm._s(_vm.timesheetExistsSubtitle)+" "),(
                      _vm.timesheetSelectedJob.job.selfAssignmentWithManageTasking
                    )?_c('span',{staticClass:"d-flex ml-1"},[_vm._v(" • "),_c('span',{staticClass:"d-inline-flex ml-1 flex-wrap"},[_c('span',{staticClass:"font-color--primary"},[_vm._v(_vm._s(_vm.$t( 'timesheetUI.TimesheetJobs.SelfAssignmentProjectLabel' )))])])]):(
                      _vm.timesheetSelectedJob.job.isClarizenAssignedProject &&
                      _vm.timesheetSelectedJob.job.isClarizenTask
                    )?_c('span',{staticClass:"d-inline-flex flex-wrap font-color--primary ml-1"},[_vm._v(" • "),_c('span',{staticClass:"ml-1 d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t( 'timesheetUI.TimesheetJobs.ClarizenAssignProjectLabel' ))+" ")]),_c('v-icon',{staticClass:"clarizen-task__label",attrs:{"color":"primary","small":""}},[_vm._v("mdi-check")])],1)]):(
                      _vm.timesheetSelectedJob.job.isClarizenTask ||
                      _vm.timesheetSelectedJob.job.selfAssignmentWithManageTasking
                    )?_c('span',{staticClass:"d-flex ml-1"},[_vm._v(" • "+_vm._s(_vm.timesheetSelectedJob.job.clarizenTaskName)+" • "),_c('span',{staticClass:"d-inline-flex ml-1 flex-wrap"},[_c('span',{staticClass:"font-color--primary"},[_vm._v(_vm._s(_vm.$t( 'timesheetUI.TimesheetJobs.ClarizenAssignTaskLabel' )))])])]):_vm._e(),(_vm.timesheetSelectedJob.job.crossCompanyRuleTypeName)?_c('span',{staticClass:"font-color--primary job-dialog__meta"},[_c('span',{staticClass:"font-color--body"},[_vm._v(" • ")]),_c('span',{staticClass:"d-inline-flex ml-1 flex-wrap"},[_vm._v(_vm._s(_vm.timesheetSelectedJob.job.crossCompanyRuleTypeName)+" ")]),_c('span',{staticClass:"d-inline-flex"},[_c('v-icon',{staticClass:"cross-company-rule__label"},[_vm._v("mdi-star")])],1)]):_vm._e()])]),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading || _vm.savingTimesheet || _vm.loadingFeatures,"aria-label":"Close modal"},on:{"click":_vm.timesheetEntryDialogClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{attrs:{"role":"presentation"}}),(_vm.loading || _vm.savingTimesheet || _vm.loadingFeatures)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('timesheet-entry-table')],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end pa-4"},[(!_vm.showForm)?_c('v-btn',{staticClass:"mr-auto",attrs:{"color":"primary","depressed":"","min-width":"160px","outlined":"","disabled":_vm.loading || _vm.savingTimesheet || _vm.loadingFeatures},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.NewEntry'))+" ")]):_vm._e(),(
                  _vm.showForm &&
                  !_vm.isTimesheetBeingCorrected &&
                  _vm.isTimesheetApproved
                )?[_c('v-btn',{staticClass:"mr-auto",attrs:{"color":"primary","depressed":"","min-width":"160px","outlined":"","disabled":_vm.loading || _vm.savingTimesheet || _vm.loadingFeatures},on:{"click":_vm.timesheetEntryDialogClose}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.Close'))+" ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","min-width":"160px","disabled":_vm.loading ||
                    _vm.savingTimesheet ||
                    _vm.loadingFeatures ||
                    !_vm.agencyData.enableSelfCorrection ||
                    !_vm.isCorrectionValid ||
                    _vm.timesheetDetail.isSelfCorrected},on:{"click":_vm.handleTimesheetCorrection}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.CorrectTimesheet'))+" ")])]:_vm._e(),(
                  _vm.showForm &&
                  _vm.isTimesheetBeingCorrected &&
                  !_vm.isTimesheetBeingReviewed
                )?[_c('v-btn',{staticClass:"mr-auto",attrs:{"color":"primary","depressed":"","min-width":"160px","outlined":""},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.Cancel'))+" ")]),(_vm.validForEntry)?_c('v-btn',{attrs:{"color":"primary","depressed":"","min-width":"160px","disabled":invalid || !_vm.hasTimeEntryChanged || _vm.shouldReviewBtnDisable},on:{"click":() => _vm.setIsTimesheetBeingReviewed(true)}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.ReviewCorrection'))+" ")]):_vm._e()]:_vm._e(),(_vm.showForm && _vm.isTimesheetBeingReviewed)?[_c('v-btn',{staticClass:"mr-auto",attrs:{"color":"primary","depressed":"","min-width":"160px","outlined":""},on:{"click":() => _vm.setIsTimesheetBeingReviewed(false)}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.Back'))+" ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","min-width":"160px"},on:{"click":_vm.handleConfirm}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.Confirm'))+" ")])]:_vm._e(),(
                  _vm.timesheetDetail &&
                  _vm.timesheetDetail.timesheetStatus === 'Rejected' &&
                  _vm.agencyData.enableSelfCorrection &&
                  _vm.timesheetDetail.selfCorrectedHours < 0
                )?_c('v-btn',{attrs:{"color":"primary","depressed":"","min-width":"160px","disabled":_vm.loadingFeatures ||
                  _vm.timesheetTotals >
                    (_vm.timesheetDetail && _vm.timesheetDetail.isPercentageEntry
                      ? 100
                      : 24)},on:{"click":() => _vm.saveTimesheet()}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.RemoveCorrection'))+" ")]):_vm._e(),(
                  _vm.validForEntry &&
                  !_vm.isTimesheetApproved &&
                  !(
                    _vm.timesheetDetail &&
                    _vm.timesheetDetail.timesheetStatus === 'Rejected' &&
                    _vm.agencyData.enableSelfCorrection &&
                    _vm.timesheetDetail.selfCorrectedHours < 0
                  )
                )?_c('v-btn',{attrs:{"color":"primary","depressed":"","type":"submit","min-width":"160px","disabled":_vm.loading ||
                  _vm.savingTimesheet ||
                  _vm.loadingFeatures ||
                  invalid ||
                  (_vm.showForm && _vm.isTimesheetApproved) ||
                  !_vm.hasTimeEntryChanged}},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.Save'))+" ")]):_vm._e()],2)],1)]}}],null,false,2590436560)})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }