var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isTimeEntryDrawerVisible)?_c('VNavigationDrawer',{staticClass:"time-entry-drawer",style:(`width: ${_vm.timeEntryDrawerCSSWidth}`),attrs:{"app":"","right":"","floating":"","disable-resize-watcher":"","temporary":_vm.isTimeEntryDrawerUnfolded,"permanent":""},model:{value:(_vm.isTimeEntryDrawerVisible),callback:function ($$v) {_vm.isTimeEntryDrawerVisible=$$v},expression:"isTimeEntryDrawerVisible"}},[_c('FocusLock',{attrs:{"returnFocus":""}},[_c('div',{attrs:{"data-no-autofocus":""}},[_c('validation-observer',{ref:"observer",attrs:{"slim":""}},[_c('VForm',{key:_vm.isLazyValidation,staticClass:"d-flex flex-column flex-grow-1",attrs:{"lazy-validation":_vm.isLazyValidation,"disabled":(_vm.isTimesheetApproved && !_vm.isTimesheetBeingCorrected) ||
            (_vm.timesheetDetail && _vm.timesheetDetail.selfCorrectedHours < 0)},on:{"submit":function($event){$event.preventDefault();return _vm.saveValidate.apply(null, arguments)}}},[_c('div',{staticClass:"time-entry-drawer__wrapper d-flex flex-column",attrs:{"data-no-focus-lock":""}},[_c('div',{staticClass:"time-entry-drawer__header"},[_c('time-entry-drawer-header',{staticClass:"px-6 py-4",attrs:{"handleClose":_vm.handleClose}}),_c('VDivider',{attrs:{"role":"presentation"}}),(_vm.loadingFeatures)?_c('VProgressLinear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('div',{staticClass:"flex-grow-1 d-flex flex-column",attrs:{"id":"navigation-drawer-body"}},[_c('div',{staticClass:"px-6 py-4 timesheet-entry__body flex-grow-1"},[_c('div',{staticClass:"timesheet-entry__form"},[(_vm.showForm)?_c('div',[(!_vm.timesheetDetail.id)?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"mb-2 accent--text font-size--14 font-weight--600 mr-5"},[_vm._v(" "+_vm._s(_vm.$t('General.Btns.NewEntry'))+" ")])]):(
                        !_vm.isTimesheetBeingReviewed &&
                        !_vm.isTemporaryExternalEmployee
                      )?_c('div',{staticClass:"text-right",style:({
                        marginBottom: '-16px',
                      })},[_c('div',{class:`d-inline-block status-pill status-pill--${_vm.timesheetDetail?.timesheetStatus?.toLowerCase()}`},[(
                            _vm.timesheetDetail?.timesheetStatus === 'Unsubmitted'
                          )?_c('span',[_vm._v(_vm._s(_vm.$t('timesheetUI.Status.Unsubmitted')))]):_vm._e(),(
                            _vm.timesheetDetail?.timesheetStatus === 'Submitted'
                          )?_c('span',[_vm._v(_vm._s(_vm.$t('timesheetUI.Status.Submitted')))]):_vm._e(),(
                            _vm.timesheetDetail?.timesheetStatus === 'Approved'
                          )?_c('span',[_vm._v(_vm._s(_vm.$t('timesheetUI.Status.Approved')))]):_vm._e(),(
                            _vm.timesheetDetail?.timesheetStatus === 'Rejected'
                          )?_c('span',[_vm._v(_vm._s(_vm.$t('timesheetUI.Status.Rejected')))]):_vm._e()])]):_vm._e(),(_vm.loading)?_c('time-entry-skeleton-loader'):_vm._e(),_c('timesheet-entry-detail',{key:_vm.preferenceDialogOpen
                          ? `${_vm.timesheetDetail?.locationCode}-${_vm.timesheetDetail?.wfh}-${_vm.timesheetDetail.id}`
                          : _vm.timesheetDetail.id})],1):_c('timesheet-entry-table')],1)])]),_c('div',{staticClass:"time-entry-drawer__footer"},[_c('v-divider',{attrs:{"role":"presentation"}}),_c('div',{staticClass:"px-3 py-4"},[_c('time-entry-drawer-footer',{attrs:{"saveValidate":_vm.saveValidate}})],1)],1)])])],1),_c('v-btn',{staticClass:"time-entry-drawer--expand-btn",attrs:{"icon":"","outlined":"","color":"primary","width":"24","height":"24","tabindex":"0","aria-label":_vm.isTimeEntryDrawerUnfolded
            ? 'view form in slim view'
            : 'view form in large view'},on:{"click":_vm.toggleTimeEntryDrawerExpansion}},[(!_vm.isTimeEntryDrawerUnfolded)?_c('v-icon',{attrs:{"size":"17"}},[_vm._v("mdi-chevron-left")]):_vm._e(),(_vm.isTimeEntryDrawerUnfolded)?_c('v-icon',{attrs:{"size":"17"}},[_vm._v("mdi-chevron-right")]):_vm._e()],1)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }